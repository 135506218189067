import * as React from "react";
import config from "../../config/config";
import Overview from "../../components/OverviewTable/Overview";
import t from "../../hooks/useTranslation";
import useDetailData from "../../hooks/useDetailData";
// import UpdateDetail from "./UpdateDetail";

// import './UpdateDetail.css';

function UpdateDetail(props) {
    const {isLoading, error, response} = useDetailData(config.apiRouteMap.getUpdate);

    console.log(response);
    return (
        <div className="UpdateDetail app__main">
            <Overview
                isLoading={isLoading}
                title={t('UpdateDetail')}
                error={error}
            >

            </Overview>
        </div>
    );
}

export default UpdateDetail;
