import React, {useEffect, useState} from 'react';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Forms from "../../form/Forms";
import {onChange} from "../../tools";
import AuthService from "../../AuthService";
import config from "../../config/config";
import t from "../../hooks/useTranslation";
import {mergeObjects} from "../../tools";
import {useParams} from "react-router-dom";

import './ProductForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    name: '',
    weight: 100,
    ean: ''
}

function ProductForm(props) {
    let {id} = useParams();
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            const data = mergeObjects(INITIAL_FORM_DATA, props.data);
            setFormData(data);
        }
    }, [props.data]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            if (id) {
                await authService.put(config.apiRouteMap.getProduct(id), formData);
            } else {
                const {data} = await authService.post(config.apiRouteMap.product, formData);
                id = data._id;
            }

            setError(null);
            setIsLoading(false);
            props.history.push(config.routeMap.products);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const deleteProduct = async () => {
        if (window.confirm(t('confirmDelete'))) {
            try {
                await authService.delete(config.apiRouteMap.getProduct(id));
            } catch (e) {
                setError(e);
            }
            props.history.push(config.routeMap.products);
        }
    }

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            onSubmit();
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <Forms.Text label={t('name')} value={formData.name} name='name'
                                        disabled={isLoading} onChange={onChange(setFormData)}
                                        onKeyPress={handleKeyPress}/>
                            <Forms.Text label={t('ean')} value={formData.ean} name='ean'
                                        disabled={isLoading} onChange={onChange(setFormData)}
                                        onKeyPress={handleKeyPress}/>
                            <Forms.Number label={t('weight')} value={formData.weight} name='weight'
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                        </div>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <div>
                        <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                        {id && <Forms.Button label={t('delete')} dark onClick={deleteProduct}/>}
                    </div>
                    <Forms.Button submit label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <React.Fragment>
            <div className="ProductContainer">
                {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
            </div>
        </React.Fragment>
    );
}

export default ProductForm;