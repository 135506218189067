import React, {useEffect, useState} from 'react';
import jwtDecode from "jwt-decode";
import AuthService from "../AuthService";
import Loading from "./Loading";
import {ReactComponent as LogoutIcon} from '../img/svg/ico-logout.svg';
import {Link} from "react-router-dom";
import history from "../history";
import t from "../hooks/useTranslation";

import './LoggedUser.css';
import {useAppData} from "../context/AppDataContext";

const authService = new AuthService();

function LoggedUser({token, setToken}) {
    const [user, setUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState();
    const {setIsMenuOpen, isMenuOpen} = useAppData();

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.get(`/api/user/${jwtDecode(token)._id}`);
                setUser(data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
            }
        })();
    }, []);

    const logout = () => {
        authService.logout();
        history.push('/');
        setToken(null);
    }

    const renderContent = () => {
        let className;
        isMenuOpen && (className = 'open');
        return (
            <div className="app__controls">
                <div className="app__controls__user">
                    <Link>
                        <strong>{`${user.name} ${user.surname}`}</strong><br/>{user.email}
                    </Link>
                </div>
                <div className="app__controls__touch logout">
                    <LogoutIcon onClick={logout}/>
                </div>
                <div className="app__controls__menu">
                    <button type="button" aria-controls="nav" aria-label="Zobrazit navigaci" data-open="Menu"
                            data-close="Zavřít" onClick={() => setIsMenuOpen(!isMenuOpen)} id="nav-toggle"
                            className={className}>
                        <span className="burger"><span/></span>
                        <span className="stamp">{t('menu')}</span>
                    </button>
                </div>
            </div>
        );
    }

    const getContent = () => {
        return isLoading ? <Loading/> : renderContent();
    }

    return (
        <div className="LoggedUser">
            {getContent()}
        </div>
    );
}

export default LoggedUser;