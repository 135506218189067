import React, {useEffect, useState} from 'react';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Forms from "../../form/Forms";
import AuthService from "../../AuthService";
import config from "../../config/config";
import t from "../../hooks/useTranslation";
import {toast} from 'react-toastify';
import moment from "moment";
import {ReactComponent as RepeatIcon} from '../../img/reload.svg';

import './SoftwareUpdatesForm.css';

const authService = new AuthService();

function SoftwareUpdatesForm(props) {
    const [swList, setSwList] = useState();
    const [version, setVersion] = useState('');
    const [error, setError] = useState();
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [storeShelfMap, setStoreShelfMap] = useState([]);
    const [shelfMap, setShelfMap] = useState();
    const [selectedShelfList, setSelectedShelfList] = useState([]);

    useEffect(() => {
        if (props?.data?.list) {
            setSwList(props.data.list);
        }
    }, [props.data]);

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.get('/api/shelf/list');
                const allShelvesIds = [];
                const shfMap = {};
                const map = data.reduce((acc, shelf) => {
                    shfMap[shelf._id] = shelf;
                    allShelvesIds.push(shelf._id);
                    acc.allStore.list.push(shelf);
                    return acc;
                }, {
                    allStore: {
                        name: 'Vše',
                        list: []
                    }
                });
                setSelectedShelfList(allShelvesIds);
                setStoreShelfMap(map);
                setShelfMap(shfMap);
            } catch (err) {
                setError(err);
            }
        })();
    }, []);

    const onSubmit = async () => {
        if (file) {
            try {
                setIsLoading(true);
                const formData = new FormData();
                formData.append('selectedShelfIdList', JSON.stringify(selectedShelfList));
                formData.append('file', file);
                const {data} = await authService.post(config.apiRouteMap.uploadSoftwareUpdate, formData);
                setSwList([data, ...swList]);
                setFile(null);
                toast.success(t('softwareUpdated'), {position: "top-center"});
                setError(null);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err);
            }
        } else {
            alert(t('selectFile'));
        }
    }

    const updateAgain = async (updateId, shelfId) => {
        try {
            setIsLoading(true);
            await authService.post(config.apiRouteMap.reupdateShelf, {updateId, shelfId});
            window.location.reload();
            setError(null);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const renderForm = () => {
        return (
            <>
                <h4>{t('updateNewFw')}</h4>
                <div className="formContent updateForm">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <Forms.File label={t('chooseSoftware')} onChange={file => setFile(file)}/>
                            <Forms.ShelfSelector label={t('selectShelves')} value={selectedShelfList}
                                                 disabled={isLoading}
                                                 onChange={(_, __, chosen) => setSelectedShelfList(chosen)}
                                                 storeShelfMap={storeShelfMap}/>

                        </div>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <div/>
                    <Forms.Button submit label={t('upload')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderShelfStatus = (sw) => {
        const getShelfName = shelfId => shelfMap ? shelfMap[shelfId]?.name : shelfId;
        const renderStatus = ({status, shelf}) => status === "failed" ?
            <RepeatIcon onClick={() => updateAgain(sw._id, shelf)} title={t('updateAgain')} width={22}/> : null;
        return <table>
            <tbody>
            {sw.shelfUpdateStatusList.map(status => <tr>
                <td className="shelfSwStatus">{`${getShelfName(status.shelf)}: ${status.status}`}{renderStatus(status)}</td>
            </tr>)}
            </tbody>
        </table>
    }

    const renderSWList = () => {
        if (!swList?.length) return null;

        return (
            <>
                <div>
                    <h4>{t('listOfUploadedSoftware')}</h4>
                    <table>
                        <thead>
                        <tr>
                            <td>Datum nahrání</td>
                            <td>Název FW</td>
                            <td>Řídící jednotka: status</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            swList?.map(sw => {
                                    return (<tr className="swRow" key={sw._id}>
                                        <td>{moment(sw.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                                        <td>{sw.filePath.split('/').pop()}</td>
                                        <td>{renderShelfStatus(sw)}</td>
                                    </tr>);
                                }
                            )
                        }
                        </tbody>
                    </table>
                </div>
                <div className="divider"/>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return (
                <div>
                    {renderSWList()}
                    {renderForm()}
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            <div className="SoftwareContainer">
                {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
            </div>
        </React.Fragment>
    );
}

export default SoftwareUpdatesForm;