const config = {
    routeMap: {
        shelves: '/shelves',
        notifications: '/notifications',
        alarms: '/alarms',
        products: '/products',
        settings: '/settings',
        allEvents: '/all-events',
        updates: '/updates',

        getShelfDetail: id => `/shelf/edit/${id}`,
        getUserDetail: id => `/user/edit/${id}`,
        getProductDetail: id => `/product/edit/${id}`,
        getUpdateDetail: id => `/update/detail/${id}`,

        addShelf: '/shelf/add',
        addProduct: '/product/add',

        editShelf: '/shelf/edit/:id',
        editProduct: '/product/edit/:id',
        detailUpdate: '/update/detail/:id'
    },
    menuItems: {
        '/shelves': ['/shelves', '/shelf/edit/[a-f\\d]{24}', '/shelf/add'],
        '/notifications': ['/notifications'],
        '/alarms': ['/alarms'],
        '/products': ['/products', '/product/edit/[a-f\\d]{24}', '/product/add'],
        '/settings': ['/settings'],
        '/all-events': ['/all-events'],
        '/updates': ['/updates'],
    },
    apiRouteMap: {
        shelf: '/api/shelf',
        alarm: '/api/alarm',
        notification: '/api/notification',
        product: '/api/product',
        productImportFile: '/api/product/import',
        appConfig: () => '/api/appConfig',
        getSoftwareUpdates: () => '/api/softwareUpdate',
        uploadSoftwareUpdate: '/api/softwareUpdate/upload',
        public: '/public/',
        user: '/api/user',
        signIn: '/api/user/signin',
        getUser: id => `/api/user/${id}`,
        getShelf: id => `/api/shelf/${id}`,
        reupdateShelf: `/api/shelf/reupdate`,
        getShelfLog: id => `/api/shelfLog/${id}`,
        getProduct: id => `/api/product/${id}`,
        deleteShelfError: (shelfId, errorId) => `/api/shelf/${shelfId}/error/${errorId}`
    },
    wssConfig: {
        messageTypes: {
            NEW: 'new',
            UPDATE: 'update'
        },
        entityTypes: {
            NOTIFICATION: 'notification',
            ALARM: 'alarm',
            EVENT: 'event',
            SHELF: 'shelf',
            SHELF_LOG: 'shelfLog',
        }
    },
    getWebSocketAddress: (token) => {
        let host = window.location.host
        if (/localhost/.test(host)) {
            host = '127.0.0.1:8300';
        }
        return `ws://${host}/ws?access_token=${token}`
    }
};

export default config;