import React, {useEffect, useState} from 'react';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Forms from "../../form/Forms";
import {onChange} from "../../tools";
import AuthService from "../../AuthService";
import config from "../../config/config";
import t from "../../hooks/useTranslation";
import {toast} from 'react-toastify';

import './AppConfigForm.css';
import {useAppData} from "../../context/AppDataContext";

const authService = new AuthService();

function AppConfigForm(props) {
    const [formData, setFormData] = useState(props.data || {});
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {updateAppConfig} = useAppData();

    useEffect(() => {
        if (props.data) {
            setFormData(props.data);
        }
    }, [props.data]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            if (formData._id) {
                const {data} = await authService.put(`${config.apiRouteMap.appConfig()}/${formData._id}`, formData);
                updateAppConfig(data);
            } else {
                await authService.post(config.apiRouteMap.appConfig(), formData);
            }
            toast.success(t('appConfigUpdated'), {position: "top-center"});
            setError(null);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            onSubmit();
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <Forms.Number value={formData.shelfAlarmWightIgnore} name='shelfAlarmWightIgnore'
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                            <Forms.Number value={formData.shelfEdgeTolerance} name='shelfEdgeTolerance'
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                            <Forms.Number value={formData.lowerThresholdCoefficient} name='lowerThresholdCoefficient'
                                          min={0} max={100}
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                            <Forms.Number value={formData.maxAlarmAge} name='maxAlarmAge'
                                          min={7} max={100}
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                            <Forms.Number value={formData.maxStatsAge} name='maxStatsAge'
                                          min={7} max={100}
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                            <Forms.Number value={formData.alarmHighlightDuration} name='alarmHighlightDuration'
                                          min={3} max={100}
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                        </div>
                        <div className="divider"/>
                        <h4>Nastavení síly signálu a odezvy regálů</h4>
                        <div className="row">
                            <Forms.Number value={formData.maxShelfDelayMs} name='maxShelfDelayMs'
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                            <Forms.Number value={formData.minShelfSignalStrengthDb} name='minShelfSignalStrengthDb'
                                          disabled={isLoading} onChange={onChange(setFormData)}
                                          onKeyPress={handleKeyPress}/>
                        </div>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <div/>
                    <Forms.Button submit label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <React.Fragment>
            <div className="AppConfigContainer">
                {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
            </div>
        </React.Fragment>
    );
}

export default AppConfigForm;