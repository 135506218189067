import React, {createContext, useContext, useEffect, useState} from "react";
import AuthService from "../AuthService";

export const AppDataContext = createContext();

const authService = new AuthService();
export const AppDataProvider = ({children}) => {
    const [menuOpen, setMenuOpen] = useState();
    const [appConfig, setAppConfig] = useState();

    const setIsMenuOpen = bool => setMenuOpen(bool);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {data} = await authService.get("/api/appConfig");
                setAppConfig(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const updateAppConfig = newAppConfig => {
        setAppConfig(newAppConfig);
    }

    return (
        <AppDataContext.Provider
            value={{isMenuOpen: menuOpen, setIsMenuOpen, alarmHighlightDuration: appConfig?.alarmHighlightDuration, updateAppConfig}}>
            {children}
        </AppDataContext.Provider>
    );
};

export const useAppData = () => {
    return useContext(AppDataContext);
};
