import * as React from "react";
import config from "../../config/config";
import Overview from "../../components/OverviewTable/Overview";
import t from "../../hooks/useTranslation";
import ShelfForm from "./ShelfForm";
import useDetailData from "../../hooks/useDetailData";
import {useEffect} from "react";
import {renderError} from "../../tools";
import {toast} from 'react-toastify';
import AuthService from "../../AuthService";

import './ShelfDetail.css';
import {useParams} from "react-router-dom";

const authService = new AuthService();

function ProductDetail(props) {
    const {id} = useParams();
    const {isLoading, error, response} = useDetailData(config.apiRouteMap.getShelf);

    const deleteError = async error => {
        await authService.delete(config.apiRouteMap.deleteShelfError(id, error._id));
    }

    useEffect(() => {
        if (!isLoading && response) {
            if (response?.errorList?.length) {
                response.errorList.map(error =>
                    toast.warning(renderError(error, true), {
                        position: "top-center",
                        autoClose: false,
                        closeOnClick: false,
                        theme: "dark",
                        onClose: () => deleteError(error)
                    })
                )
            }
        }
    }, [isLoading, response]);

    return (
        <div className="ShelfDetail app__main">
            <Overview
                isLoading={isLoading}
                title={t('shelfDetail')}
                error={error}
            >
                <ShelfForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default ProductDetail;
