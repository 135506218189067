import React from 'react';
import './Success.css';

export default function Success({label}) {
    return (
        <div className="Success alert success" role="alert">
            {label}
        </div>
    );
}
