import * as React from "react";
import {useState} from "react";
import Forms from '../../../form/Forms';
import t from "../../../hooks/useTranslation";

import './Boolean.css';

function Boolean(props) {
    const [visible, setVisible] = useState(false);

    const boolOptions = [
        {label: t('yes'), value: true},
        {label: t('no'), value: false},
    ];

    const label = props.value && t(props.value) || props.label;
    const value = props.value || '';
    return (
        <div className='BooleanFilter'
             onMouseEnter={() => setVisible(true)}
             onMouseLeave={() => setVisible(false)}>
            <label className='filter-value'>{label}</label>
            <div className="container" style={
                {
                    display: visible ? 'block' : 'none'
                }}>
                <Forms.Select options={boolOptions} emptyOption value={value} onChange={props.onChange}/>
            </div>
        </div>
    );
}

export default Boolean;