import React, {useEffect, useRef, useState} from "react";
import {uniqueId} from "../tools";
import "./CheckboxAccordion.css";

const CheckboxAccordion = ({label, items, selected, onChange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const checkboxRef = useRef();

    const handleSelectAll = (e) => {
        onChange(e.target.checked, items.map(item => item._id));
    };

    const handleSelect = (e) => {
        onChange(e.target.checked, [e.target.value]);
    }

    const handleCheckboxState = () => {
        const allSelected = items.every(item => selected.indexOf(item._id) !== -1);
        const someSelected = items.some(item => selected.indexOf(item._id) !== -1);
        if (allSelected) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else if (someSelected) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = true;
        } else {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        }
    }

    // set initial checkbox state
    useEffect(() => {
        handleCheckboxState();
    }, []);

    useEffect(() => {
        handleCheckboxState();
    }, [selected]);

    const id = uniqueId();
    return (
        <div className="accordion">
            <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
                <input
                    id={id}
                    type="checkbox"
                    ref={checkboxRef}
                    className="accordion-header-checkbox"
                    onChange={e => {
                        e.stopPropagation();
                        handleSelectAll(e);
                    }}
                />
                <label htmlFor={id} className="accordion-header-label">{label}</label>
                <div className={`accordion-header-arrow ${isOpen ? "accordion-header-arrow-open" : ""}`}
                     onClick={() => setIsOpen(!isOpen)}>
                    <span/>
                </div>
            </div>
            <div className={`accordion-content ${isOpen ? "accordion-content-open" : ""}`}>
                {items.map((item) => {
                    const chId = uniqueId();
                    return <div key={item._id}>
                        <input type="checkbox" className="accordion-checkbox" value={item._id} id={chId}
                               checked={selected.indexOf(item._id) !== -1} onChange={handleSelect}/>
                        <label htmlFor={chId} className="accordion-label">{item.name}</label>
                    </div>;
                })}
            </div>
        </div>
    );
};

export default CheckboxAccordion;