import Checkbox from './Checkbox';
import LoginButton from './LoginButton';
import Button from './Button';
import Text from './Text';
import Select from "./Select";
import Date from "./Date";
import Dropdown from "./Dropdown";
import NestedForm from "./NestedForm";
import File from "./File";
import Checkboxes from "./Checkboxes";
import List from "./List";
import Number from "./Number";
import DateTime from "./DateTime";
import Autocomplete from "./Autocomplete";
import ShelfSelector from "./ShelfSelector";

import '../css/app/form.css';

const Forms = {};

Forms.Checkbox = Checkbox;
Forms.Button = Button;
Forms.LoginButton = LoginButton;
Forms.Text = Text;
Forms.List = List;
Forms.Select = Select;
Forms.Date = Date;
Forms.DateTime = DateTime;
Forms.Dropdown = Dropdown;
Forms.NestedForm = NestedForm;
Forms.File = File;
Forms.Checkboxes = Checkboxes;
Forms.Number = Number;
Forms.Autocomplete = Autocomplete;
Forms.ShelfSelector = ShelfSelector;

export default Forms;