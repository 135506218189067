import React, {useState} from 'react';
import ShelfConfigItemForm from "./ShelfConfigItemForm";
import t from "../../hooks/useTranslation";
import Forms from "../../form/Forms";
import useFetch from "../../hooks/useFetch";
import config from "../../config/config";

import "./ShelfConfigForm.css";
import Loading from "../../components/Loading";

function ShelfConfigForm(props) {
    const [shelfList, setShelfList] = useState(props.value);
    const {isLoading, response} = useFetch(config.apiRouteMap.product, 'GET');

    const getShelfRestWidth = (shelfIndex) => {
        if (props.shelfWidth) {
            return parseInt(props.shelfWidth - shelfList[shelfIndex].reduce((width, shelfSector) => width += parseInt(shelfSector.width), 0));
        }
        return 25;
    }

    const addShelf = (i = null) => {
        const newFormData = [...shelfList];
        if (i !== null) {
            const shelfRestWidth = getShelfRestWidth(i);
            newFormData[i].push({width: parseInt(shelfRestWidth), product: '', alarmFrom: 5});
        } else {
            newFormData.push([{width: 25, product: '', alarmFrom: 5}]);
        }
        setShelfList(newFormData);
        props.customOnChange(newFormData)
    }

    const removeShelf = (i) => {
        const newFormData = shelfList.filter((item, n) => i !== n);
        setShelfList(newFormData);
        props.customOnChange(newFormData)
    }

    const _renderDeleteButton = (i) => {
        return shelfList.length > 1 && (
            <button onClick={() => removeShelf(i)}
                    className="button secondary small">{t('deleteShelf')}</button>
        );
    }

    const onChangeShelfForm = (i) => {
        return (key, val) => {
            const newFormData = [...shelfList];
            newFormData[i][key] = val;
            console.log(newFormData);
            setShelfList(newFormData);
            props.onChange(null, 1, newFormData);
        }
    }

    const deleteShelfSector = (i) => {
        return (n) => {
            const newFormData = [...shelfList];
            newFormData[i].splice(n, 1);
            setShelfList(newFormData);
            props.customOnChange(newFormData);
        }
    }

    return isLoading ? <Loading/> : (
        <div className="ShelfConfigForm">
            {shelfList.map((row, i) => {
                let n = i;
                return (
                    <div className="shelfSection" key={`ShelfConfigItem_${i}`}>
                        <div className="header-container">
                            <h3>
                                {++n}. police
                                <button type="button" onClick={() => addShelf(i)}
                                        className="button primary small">{t('addShelfSection')}</button>
                            </h3>
                            {_renderDeleteButton(i)}
                        </div>
                        <ShelfConfigItemForm onChange={onChangeShelfForm} value={row} key={Math.random()}
                                             products={response.list || []}
                                             deleteShelfSector={deleteShelfSector(i)}/>
                    </div>
                );
            })}
            <Forms.Button onClick={() => addShelf()} className="button primary small" label={t('addShelf')}/>
        </div>
    );
}

export default ShelfConfigForm;