import * as React from "react";

import Overview from "../../components/OverviewTable/Overview";
import t from "../../hooks/useTranslation";
import ProductForm from "./ProductForm";

import './ProductAdd.css';

function ProductAdd(props) {

    return (
        <div className="ProductAdd app__main">
            <Overview title={t('newProduct')}>
                <ProductForm key={Math.random() * 9999} history={props.history}/>
            </Overview>
        </div>
    );
}

export default ProductAdd;
