import './Checkbox.css';
import {uniqueId} from "../tools";

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder'];

function Checkbox(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === "value") {
                result["checked"] = value;
            } else if (elementProps.indexOf(propName) !== -1) {
                result[propName] = value;
            }
        });
        return result;
    }

    let style = props.style || {};
    const id = uniqueId();
    const colWidth = props.colWidth || 25;
    return (
        <label className={`FormCheckbox col-${colWidth}`} style={style} htmlFor={id}>
            <input type="checkbox" {...getMainProps(inputMainProps)} id={id}/>
            <label htmlFor={id}>{props.label}</label>
        </label>
    );
}

export default Checkbox;