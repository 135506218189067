import React, {useState} from "react";
import CheckboxAccordion from "./CheckboxAccordion";
import t from "../hooks/useTranslation";

import "./ShelfSelector.css";

const ShelfSelector = ({storeShelfMap, onChange, value, name}) => {
    const [selectedList, setSelectedList] = useState(value);

    const handleSelect = (selected, machineIdList) => {
        let newSelectedList = new Set(selectedList);
        if (selected) {
            machineIdList.forEach(id => newSelectedList.add(id));
        } else {
            machineIdList.forEach(id => newSelectedList.has(id) && newSelectedList.delete(id));
        }
        setSelectedList([...newSelectedList]);
        onChange(null, name, [...newSelectedList]);
    }

    return <div className="ShelfSelector col-50">
        <div>
            <label>{t('selectShelves')}</label>
            {
                Object.entries(storeShelfMap).map(([clientId, data]) => {
                    const items = data.list.map(shelf => {
                        shelf.name += ` ${shelf.serialNumber}`;
                        return shelf;
                    })
                    return <CheckboxAccordion label={data.name} items={items} key={clientId}
                                              selected={selectedList} onChange={handleSelect}/>;
                })
            }
        </div>
    </div>;
};

export default ShelfSelector;
