import {getButtonStyles} from "../tools";
import {useState} from "react";

import './Button.css';

function Button(props) {
    const [isActive, setIsActive] = useState(false);

    let className = "Button button main";

    // custom lass
    if (props.className) {
        className += ` ${props.className}`;
    }

    // dark class
    if (props.dark) {
        className += ` secondary`;
    }

    // back class
    if (props.submit) {
        className += ` submit`;
    }

    // disabled class
    if (props.disabled) {
        className += ` disabled`;
    }

    const disabled = props.disabled || false;
    const type = props.type || 'button';

    let styles;
    if (props.disabled) {
        styles = getButtonStyles('buttonDisabled');
    } else if (isActive) {
        styles = getButtonStyles('buttonActive');
    } else if (props.dark) {
        styles = getButtonStyles('buttonDark');
    } else {
        styles = getButtonStyles('buttonLight');
    }

    if (props.style) {
        styles = props.style;
    }
    return (
        <button onMouseDown={() => setIsActive(true)} onMouseUp={() => setIsActive(false)}
                className={className} type={type} onClick={props.onClick} disabled={disabled} style={styles}>
            {props.label}
        </button>
    );
}

export default Button;