import React from 'react';
import ReactPaginate from 'react-paginate';
import t from "../../hooks/useTranslation";

import './OverviewPagination.css';

function OverviewPagination({pageCount, onChange}) {
    return (
        <nav className="OverviewPagination">
            <ReactPaginate
                breakLabel="..."
                nextLabel={t('next')}
                onPageChange={onChange}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                previousLabel={t('previous')}
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="button"
                previousClassName="prev-page-item"
                previousLinkClassName="page-link"
                nextClassName="next-page-item"
                nextLinkClassName="page-link"
            />
        </nav>
    );
}

export default OverviewPagination;