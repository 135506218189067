import React, {useEffect, useState} from 'react';
import Forms from "../../form/Forms";
import t from "../../hooks/useTranslation";

import './ShelfConfigItemForm.css';

function ShelfConfigItemForm({value, onChange, deleteShelfSector, isLoading, products}) {
    const [shelf, setShelf] = useState(value);

    useEffect(() => {
        setShelf(value);
    }, [value]);

    const updateShelf = i => {
        return (e) => {
            const newShelfConf = [...shelf];
            newShelfConf[i][e.target.name] = e.target.value;
            setShelf(newShelfConf);
            onChange(e.target.name, e.target.value);
        };
    }

    const updateProduct = i => {
        return (_, {value: productId}) => {
            const newShelfConf = [...shelf];
            newShelfConf[i].productId = productId;
            setShelf(newShelfConf);
            onChange("productId", productId);
        }
    }

    const optionList = products?.map(item => ({
        label: `${item.name} (${item.weight}g)`,
        value: item._id
    }))

    return (
        <div className="ShelfConfigItemForm">
            {
                shelf.map((shelfColumn, i) => {
                    return (
                        <div className="row" key={`shelfConfigItemCol_${i}`}>
                            <Forms.Number colWidth={100} label={t('width')} value={shelfColumn.width} name='width'
                                          disabled={isLoading} onChange={updateShelf(i)}/>
                            <Forms.Autocomplete colWidth={100} label={t('product')}
                                                value={optionList.find(({value}) => value === shelfColumn.productId) || ''}
                                                name='product' disabled={isLoading} onChange={updateProduct(i)}
                                                options={optionList} placeholder={t('typeName')}/>
                            <Forms.Number colWidth={100} label={t('alarmFrom')} value={shelfColumn.alarmFrom}
                                          name='alarmFrom'
                                          disabled={isLoading} onChange={updateShelf(i)}/>
                            {shelf.length > 1 && <button type="button" onClick={() => deleteShelfSector(i)}
                                                         className="button secondary small">{t('deleteShelfSector')}</button>}
                        </div>
                    );
                })
            }

        </div>
    );
}

export default ShelfConfigItemForm;