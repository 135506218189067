import * as React from "react";

import './File.css';

const inputMainProps = ['required', 'name', 'value', 'accept'];

function File(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const handleFileChange = (e) => {
        props?.onChange?.(e.target.files[0]);
    }

    const disabled = props.disabled || false;
    return (
        <div className="FormFile col-50">
            <label htmlFor={props.name}>{props.label}</label>
            <input id={props.name} className="button secondary" type="file" {...getMainProps(inputMainProps)}
                   onChange={handleFileChange} disabled={disabled}/>
        </div>
    );
}

export default File;