import {useState} from "react";
import * as React from "react";
import LogoPng from '../img/logo.png';
import Forms from '../form/Forms';
import AuthService from "../AuthService";
import Error from "../components/Error";
import t from "../hooks/useTranslation";
import Success from "../components/Success";
import './Login.css';

const authService = new AuthService();

function Login(props) {
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [form, setForm] = useState(window.location.pathname === '/passwordReset' ? 'passwordReset' : 'login');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        setError(null);
        setSuccess(null);

        if (form === 'login') {
            login();
        } else if (form === 'forgottenPassword') {
            forgottenPassword();
        } else if (form === 'passwordReset') {
            passwordReset();
        }
    }

    const parseUserIdAndToken = () => {
        const match = window.location.href.match("^.*token=([0-9a-f]+).*.*id=([0-9a-f]+).*$");
        if (match) {
            return {
                token: match[1],
                userId: match[2]
            }
        }
    }

    const passwordReset = async () => {
        // match
        if (password !== passwordAgain) {
            setError(t('passwordDoesNotMatch'));
            return;
        }
        // min. length
        if (password.length < 6) {
            setError(t('passwordTooShort'));
            return;
        }

        try {
            setIsLoading(true);
            const {userId, token} = parseUserIdAndToken();
            const accessToken = await authService.setNewPassword(userId, token, password, passwordAgain);
            props.setToken(accessToken);
            window.location.href = '/photos';
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const forgottenPassword = async () => {
        try {
            setIsLoading(true);
            await authService.forgottenPassword(email);
            setSuccess(t('resetPasswordEmailSent'));
            setForm('login');
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const login = async () => {
        try {
            setIsLoading(true);
            const accessToken = await authService.login(email, password);
            props.setToken(accessToken);
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            handleSubmit();
        }
    }

    const renderHeader = () => {
        if (form === 'login') {
            return t('logInToApplication');
        } else if (form === 'forgottenPassword') {
            return t('resetForgottenPassword');
        } else if (form === 'passwordReset') {
            return t('setNewPassword');
        }
    }

    const _handleChangeForm = () => {
        setError(null);
        setSuccess(null);
        setForm(form === "login" ? 'forgottenPassword' : 'login');
    }

    const ForgottenPassword = <React.Fragment>
        <Forms.Text name="email" value={email} label={t('loginName')} onKeyPress={handleKeyPress} colWidth={100}
                    onChange={e => setEmail(e.target.value)} className="input-container"/>
        <div className="login__options">
            <div className="remember check-container"/>
            <div className="forgotten">
                <button className="forgotten-password" onClick={_handleChangeForm}>{t('goBack')}</button>
            </div>
        </div>
    </React.Fragment>;

    const LoginForm = <React.Fragment>
        <Forms.Text name="email" value={email} label={t('loginName')} onKeyPress={handleKeyPress} colWidth={100}
                    onChange={e => setEmail(e.target.value)} className="input-container"/>
        <Forms.Text name="password" type="password" value={password} label={t('password')} colWidth={100}
                    className="input-container" onChange={e => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}/>
        <div className="login__options">
            <div className="remember check-container">
                <input type="checkbox" name="remember" id="login-remember"/>
                <label className="FormCheckbox" htmlFor="login-remember">Pamatovat uživatele</label>
            </div>
            {/*<div className="forgotten">*/}
            {/*    <button className="forgotten-password"*/}
            {/*            onClick={_handleChangeForm}>{t('forgottenPassword')}</button>*/}
            {/*</div>*/}
        </div>
    </React.Fragment>;

    const PasswordRecovery = <React.Fragment>
        <Forms.Text name="password" type="password" value={password} label={t('password')} colWidth={100}
                    className="input-container" onChange={e => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}/>
        <Forms.Text name="passwordAgain" type="password" value={passwordAgain} label={t('passwordAgain')} colWidth={100}
                    className="input-container" onChange={e => setPasswordAgain(e.target.value)}
                    onKeyPress={handleKeyPress}/>
    </React.Fragment>;

    const _renderForm = () => {
        if (form === "login") {
            return LoginForm;
        } else if (form === "passwordReset") {
            return PasswordRecovery;
        } else {
            return ForgottenPassword;
        }
    }

    return (
        <div className="login">
            <section className="login__main">
                <div className="logo logo--color">
                    <img src={LogoPng} alt="360°"/>
                    <span>{(t('shelfMonitoring'))}</span>
                </div>
                <h1>{renderHeader()}</h1>
                <div className="login__form">
                    {_renderForm()}
                    {error && <Error error={error}/>}
                    {success && <Success label={success}/>}
                    <button onClick={handleSubmit} className="button main full"
                            disabled={isLoading}>{t(form === 'login' ? 'logIn' : 'recoverPassword')}</button>

                </div>
            </section>
            <aside className="login__desc">
                <div className="login__desc__title">
                    <h2>{t('appName')}</h2>
                    <p dangerouslySetInnerHTML={{__html: t('appDescription')}}/>
                </div>
                <div className="login__desc__footer">
                    <p className="login__desc__links">
                        <a href="http://system.shelfmonitor.online/" target="_blank">{t('site')}</a>
                        {/*<a href="https://pixeldesign.cz" target="_blank">{t('site2')}</a>*/}
                    </p>
                    {/*<p>{t('appProvider')}</p>*/}
                    {/*<p>{t('appInfo')}</p>*/}
                </div>
                <div className="login__desc__img">
                    {/*<picture>*/}
                    {/*    <source srcSet={webpImage} type="image/webp"/>*/}
                    {/*    <img alt="" src={pngImage} loading="lazy"/>*/}
                    {/*</picture>*/}
                </div>
            </aside>
        </div>
    );
}

export default Login;
