import * as React from "react";
import config from "../../config/config";
import {Link} from "react-router-dom";
import useOverviewData from "../../hooks/useOverviewData";
import Overview from "../../components/OverviewTable/Overview";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import t from "../../hooks/useTranslation";
import moment from "moment";
import Button from "../../form/Button";
import {useState} from "react";
import AuthService from "../../AuthService";
import {NotificationContainer, NotificationManager} from 'react-notifications';

import './Products.css';

const authService = new AuthService();

function Products() {
    const {
        isLoading,
        error,
        response
    } = useOverviewData(config.apiRouteMap.product);
    const [file, setFile] = useState();
    const [isUploading, setIsUploading] = useState();

    const columns = [
        {
            key: 'name',
            title: t('name'),
            linkRenderer: (data) => ({
                route: config.routeMap.getProductDetail(data._id),
                label: data.name
            })
        },
        {
            key: 'weight',
            title: t('weight'),
            valueRenderer: ({weight}) => `${weight.toLocaleString(undefined, {minimumFractionDigits: 0})} g`
        },
        {
            key: 'createdAt',
            title: t('createdAt'),
            valueRenderer: ({createdAt}) => moment(createdAt).format('DD.MM.YYYY HH:mm')
        }
    ];

    const uploadFile = async () => {
        if (file) {
            if (window.confirm(t('uploadFileWillUpdateExisting'))) {
                try {
                    setIsUploading(true);
                    const formData = new FormData();
                    formData.append('file', file);
                    await authService.put(config.apiRouteMap.productImportFile, formData);
                    window.location.reload();
                } catch (err) {
                    if (err.response.data.errorCode === 'missingColumnKeys') {
                        const missingKeys = err.response.data.error.missingKeys.map(key => `"${key}"`).join(', ');
                        NotificationManager.error(t('importFileMissingKeys', {missingKeys}));
                    }
                } finally {
                    setIsUploading(false);
                }
            }
        } else {
            alert(t('noFileSelected'));
        }
    }

    const actions = [
        {
            component: ({_id}) => <Link className="button secondary small" to={config.routeMap.getProductDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [
        <Link className="button secondary" to={config.routeMap.addProduct}>{t('newProduct')}</Link>,
        <div className="section">
            <input type="file" className="button secondary" onChange={(e) => setFile(e.target.files[0])}/>
            <Button disabled={isUploading} className="button secondary" label={t('selectXlsxFile')}
                    onClick={uploadFile}/>
        </div>
    ];

    return (
        <div className="Products app__main">
            <NotificationContainer/>
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('productAdministration')}
                error={error}
            >
                <OverviewTable actions={actions} title={t('productList')} columns={columns}
                               rows={response?.list}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Products;
