import * as React from "react";
import {useEffect, useState} from "react";
import config from "../../config/config";
import useOverviewData from "../../hooks/useOverviewData";
import Overview from "../../components/OverviewTable/Overview";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import t from "../../hooks/useTranslation";
import OverviewPagination from "../../components/OverviewTable/OverviewPagination";
import useWebSocket, {ReadyState} from 'react-use-websocket';
import moment from "moment";
import AuthService from "../../AuthService";
import usePageVisibility from "../../hooks/usePageVisibility";
import sound from "../../assets/audio/1.wav";
import {useAppData} from "../../context/AppDataContext";

import './Alarms.css';
import {downloadCSV, formatDataByColumns} from "../../tools";

const authService = new AuthService();

const INITIAL_BRICKS_DATA = [
    {
        label: t('mostTakenProduct'),
        isLoading: true,
        dataKey: 'mostTakenProduct'
    },
    {
        label: t('mostActiveShelf'),
        isLoading: true,
        dataKey: 'mostActiveShelf'
    },
    {
        label: t('dayNotificationCount'),
        isLoading: true,
        dataKey: 'dayNotificationCount'
    },
    {
        label: t('mostActiveHour'),
        isLoading: true,
        dataKey: 'mostActiveHour',
    }
];

function Alarms() {
    const [count, setCount] = useState(0);
    const visibilityStatus = usePageVisibility();
    const {alarmHighlightDuration} = useAppData();
    const {
        isLoading,
        error,
        response,
        pageInfo,
        setPageIndex,
        bricksData,
        totalItemsCount,
        setFilter,
        filter,
        filterValues,
        loadAllFilteredData
    } = useOverviewData(config.apiRouteMap.alarm, 20, INITIAL_BRICKS_DATA);
    const [totalCount, setTotalCount] = useState(0);
    const [overviewList, setOverviewList] = useState([]);
    const [initialMessageSent, setInitialMessageSent] = useState(false);
    const {readyState, sendJsonMessage} = useWebSocket(config.getWebSocketAddress(authService.getToken()), {
        onClose: () => {
            setInitialMessageSent(false);
        },
        shouldReconnect: () => true,
        onMessage: message => processMessage(message)
    });

    useEffect(() => {
        if (!visibilityStatus && document.previousTitle) {
            document.title = document.previousTitle;
            document.previoustitle = undefined;
            setCount(0);
        }
    }, [visibilityStatus]);

    const processMessage = (message) => {
        message = JSON.parse(message.data);
        if (message.entity === config.wssConfig.entityTypes.ALARM && message.messageType === config.wssConfig.messageTypes.NEW) {
            const newCount = count + 1;
            setCount(newCount);
            if (visibilityStatus) {
                if (!document.previousTitle) {
                    document.previousTitle = document.title;
                }
                document.title = t('newAlarm', {count: newCount});
            }
            // concat new alarm log to overviewList
            message.data.new = true;
            const newList = [message.data, ...overviewList].filter((_, i) => i < 20);
            setOverviewList(newList);
            setTotalCount(totalCount + 1);
            try {
                new Audio(sound).play();
            } catch (error) {
                console.warn("Nepodařilo se přehrát zvuk: ", error);
            }
        }
    }

    useEffect(() => {
        if (response?.list) {
            setOverviewList(response.list);
        }
        if (response?.count) {
            setTotalCount(response.count);
        }
    }, [response]);

    useEffect(() => {
        if (!initialMessageSent && readyState === ReadyState.OPEN && !isLoading && !error) {
            const last = overviewList?.[0]?.createdAt;
            sendJsonMessage({last});
            setInitialMessageSent(true);
        }
    }, [readyState, isLoading, error, response, initialMessageSent]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const handleFilterChange = (key, value, type) => {
        let newFilter = {...filter};
        newFilter[key] = {type, value};
        if (
            (type === "text" && value === "")
            || (type === "select" && value === undefined)
        ) {
            delete newFilter[key];
        }
        setFilter(newFilter);
    };

    const getShelfOptions = () => {
        if (filterValues?.shelfList) {
            return filterValues.shelfList.map(({name, serialNumber, _id}) => ({
                label: name || serialNumber,
                value: _id
            }));
        }
        return [];
    }

    const columns = [
        {
            key: 'shelf',
            title: t('shelfName'),
            linkRenderer: ({shelf}) => ({
                route: config.routeMap.getShelfDetail(shelf._id),
                label: shelf.name || shelf.serialNumber
            }),
            filter: {
                type: 'select',
                filterState: filter,
                emptyOption: true,
                onChange: handleFilterChange,
                label: t('shelf'),
                options: getShelfOptions()
            }
        },
        {
            key: 'shelfNum',
            title: t('shelfSectorNumber'),
            valueRenderer: ({data}) => data.shelfNum
        },
        {
            key: 'product',
            title: t('item'),
            valueRenderer: item => item.product || '-',
            filter: {
                type: 'text',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('product')
            }
        },
        {
            key: 'distanceFromLeft',
            title: t('shelfSector'),
            valueRenderer: ({data}) => data.distanceFromLeft
        },
        {
            key: 'createdAt',
            title: t('createdAt'),
            valueRenderer: ({createdAt}) => moment(createdAt).format('DD.MM.YYYY HH:mm'),
            filter: {
                type: 'dateRange',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('alarmDate')
            }
        },
        {
            key: 'count',
            title: t('itemsCount'),
            filter: {
                title: t('countFilterTitle'),
                type: 'number',
                filterState: filter,
                onChange: handleFilterChange,
                label: t('itemsCount')
            }
        }
    ];

    const handlePageOnChange = (event) => {
        setPageIndex(event.selected);
    };

    const rowStyleRenderer = (row) => {
        const duration = alarmHighlightDuration || 5;
        if (row.new) return {
            animation: `fadeIt ${duration}s ease-in-out`,
            WebkitAnimation: `fadeIt ${duration}s ease-in-out`, // pro podporu starších prohlížečů
            MozAnimation: `fadeIt ${duration}s ease-in-out`,
            OAnimation: `fadeIt ${duration}s ease-in-out`
        };
    }

    const formatNumber = (number) => {
        const reversedStr = number.toString().split('').reverse().join('');
        const chunks = reversedStr.match(/.{1,3}/g);
        return chunks.map(chunk => chunk.split('').reverse().join('')).reverse().join(' ');
    }

    const formattedNumber = formatNumber(totalItemsCount);

    const downloadCsv = async () => {
        const list = await loadAllFilteredData();
        const preparedData = formatDataByColumns(columns, list);
        downloadCSV(preparedData, `alarms-${moment().format('DD-MM-YYYY_HH-mm')}.csv`);
    };

    return (
        <div className="Alarms app__main">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('alarmsAdministration')}
                error={error}
                wsStatus={connectionStatus}
                bricks={bricksData}
            >
                <OverviewTable title={<><strong>{t('items').toUpperCase()}</strong> ({formattedNumber})</>}
                               columns={columns} rows={overviewList}
                               resetFilter={() => setFilter({})}
                               filter={filter} isLoading={isLoading} error={error}
                               rowStyleRenderer={rowStyleRenderer}
                               downloadFn={downloadCsv}
                />
                {totalItemsCount > 0 &&
                <OverviewPagination response={response} onChange={handlePageOnChange}
                                    pageCount={Math.ceil(totalItemsCount / pageInfo.pageSize)}/>}
            </Overview>
        </div>
    );
}

export default Alarms;
