import React from 'react';
import './Error.css';
import st from "../hooks/useServerTranslation";
import t from "../hooks/useTranslation";

export default function Error({error}) {
    let message = error;
    if (error.response?.data?.errorCode) {
        console.log(`Error code (${error.response.status}-${error.response.statusText}): ${error.response.data.errorCode}`);
        if (error.response.data?.error?.code === 11000) {
            message = t('duplicateError', {keys: Object.keys(error.response.data.error.keyPattern).join(', ')})
        } else {
            message = st(error.response.data.errorCode);
        }
    } else if (error?.response?.data) {
        let {data} = error.response;
        message = `${error.message}: ${data.errorMessage}`;
    } else if (error.message) {
        message = error.message;
    }

    return (
        <div className="Error alert alert-danger" role="alert">
            {message}
        </div>
    );
}
