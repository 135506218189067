import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {AppDataProvider} from "./context/AppDataContext";
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'react-dropdown/style.css';
import 'react-notifications/lib/notifications.css';

// react-toastify
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

// FA icons
import '@fortawesome/fontawesome-svg-core/styles.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from "@fortawesome/free-solid-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";

library.add(fas, fab);

ReactDOM.render(
    <AppDataProvider>
        <ToastContainer style={{width: "50%"}}/>
        <App/>
    </AppDataProvider>
    , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
