import * as React from "react";
import {useState} from "react";
import {DateRangePicker} from 'react-date-range';
import {cs} from 'react-date-range/dist/locale';
import {defaultStaticRanges, defaultInputRanges} from "./defaultRanges";
import {uniqueId} from "../../../tools";
import {ReactComponent as Icon} from '../../../img/ico-date.svg';

import './DateRange.css';

function DateRange(props) {
    const [visible, setVisible] = useState(false);

    const _renderDateRange = () => {
        if (props.value?.dateRange) {
            const {startDate, endDate} = props.value.dateRange;
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
    }

    const ranges = props?.value?.dateRange || {startDate: new Date(), endDate: new Date(), key: 'dateRange'}
    const label = _renderDateRange() || props.label;
    const id = uniqueId();
    return (
        <div className="DateRangeFilter app__filter__item ico"
             onMouseLeave={() => setVisible(false)}
        >
            <label htmlFor={id}>{label}</label>
            <input type="text" id={id} onChange={props.onChange} onClick={() => setVisible(true)}/>
            <Icon/>
            <div className="container"
                 style={
                     {
                         display: visible ? 'block' : 'none',
                         zIndex: visible ? 3 : 0
                     }}>
                <DateRangePicker staticRanges={defaultStaticRanges}
                                 inputRanges={defaultInputRanges}
                                 locale={cs} ranges={[ranges]} onChange={props.onChange}/>
            </div>
        </div>
    );
}

export default DateRange;