import * as React from "react";
import {NavLink, useLocation} from "react-router-dom";
import config from "../config/config";
import t from "../hooks/useTranslation";
import AuthService from "../AuthService";
import {
    getNavbarButtonStyles
} from "../tools";
import {ReactComponent as ProductsIcon} from '../img/svg/ico-regaly.svg';
import {ReactComponent as NotificationsIcon} from '../img/svg/ico-oznameni.svg';
import {ReactComponent as AlarmsIcon} from '../img/svg/ico-alarmy.svg';
import {ReactComponent as ShelvesIcon} from '../img/svg/ico-regaly.svg';
import logo from '../img/m2c.png';
import {useAppData} from "../context/AppDataContext";

import './Navbar.css';

const authService = new AuthService();

function Navbar() {
    const {pathname} = useLocation();
    const {isMenuOpen, setIsMenuOpen} = useAppData();

    const isActive = (route) => {
        return config.menuItems[route]?.some(regex => {
            return pathname.match(`^${regex}$`);
        });
    }

    const menuItem = (label, route, icon, profileList) => {
        // zkontrolujeme, že uživatel má profil, který je definovaný v profileList
        if (profileList) {
            const userProfiles = authService.getUserProfileList();
            const hasAccess = profileList.some(validProfile => userProfiles.indexOf(validProfile) !== -1);
            if (!hasAccess) {
                return null;
            }
        }

        const isRouteActive = isActive(route);

        return (
            <li>
                <NavLink to={route} activeClassName='selected' onClick={() => setIsMenuOpen(false)}
                         isActive={() => isActive(route)} style={getNavbarButtonStyles(isRouteActive)}>
                    {icon}
                    <span>{label}</span>
                </NavLink>
            </li>
        );
    }

    let className = "app__navbar";
    isMenuOpen && (className += " open");
    return (
        <aside className={className} id="nav">
            <div className="logo logo--white">
                <img src={logo} alt="360°"/>
                <span>{t('shelfMonitoring')}</span>
            </div>
            <nav className="app__nav">
                <ul>
                    {menuItem(t('shelves'), config.routeMap.shelves, <ShelvesIcon alt="menu-icon"/>)}
                    {menuItem(t('notifications'), config.routeMap.notifications, <NotificationsIcon alt="menu-icon"/>)}
                    {menuItem(t('alarms'), config.routeMap.alarms, <AlarmsIcon alt="menu-icon"/>)}
                    {menuItem(t('products'), config.routeMap.products, <ProductsIcon alt="menu-icon"/>)}
                    {menuItem(t('settings'), config.routeMap.settings, <ProductsIcon alt="menu-icon"/>)}
                    {menuItem(t('allEvents'), config.routeMap.allEvents, <ProductsIcon alt="menu-icon"/>, ["SuperAdmin"])}
                    {menuItem(t('updates'), config.routeMap.updates, <ProductsIcon alt="menu-icon"/>, ["SuperAdmin"])}
                </ul>
            </nav>
        </aside>
    );
}

export default Navbar;
