import * as React from "react";
import config from "../../config/config";
import Overview from "../../components/OverviewTable/Overview";
import t from "../../hooks/useTranslation";
import useDetailData from "../../hooks/useDetailData";
import SoftwareUpdatesForm from "./SoftwareUpdatesForm";

import './Updates.css';

function Updates(props) {
    const {isLoading, error, response} = useDetailData(config.apiRouteMap.getSoftwareUpdates);

    return (
        <div className="Updates app__main">
            <Overview
                isLoading={isLoading}
                title={t('softwareUpdates')}
                error={error}
            >
                <SoftwareUpdatesForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default Updates;
