import * as React from "react";

import Overview from "../../components/OverviewTable/Overview";
import t from "../../hooks/useTranslation";
import ShelfForm from "./ShelfForm";

import './ShelfAdd.css';

function ProductAdd(props) {

    return (
        <div className="ShelfAdd app__main">
            <Overview title={t('newShelf')}>
                <ShelfForm key={Math.random()*9999} history={props.history}/>
            </Overview>
        </div>
    );
}

export default ProductAdd;
