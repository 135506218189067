import t from "./hooks/useTranslation";
import moment from "moment";

export const onChange = (setFormData) => (e, key, val) => {
    let value;
    let name;
    if (e) {
        name = e.target.name;
        switch (e.target.type) {
            case 'checkbox':
                value = e.target.checked;
                break;
            case 'number':
                value = e.target.value ? parseFloat(e.target.value) : '';
                break;
            case 'file':
                value = e.target.files;
                break;
            default:
                value = e.target.value;
        }
    } else {
        value = val;
        name = key;
    }
    setFormData(prevState => ({
            ...prevState,
            [name]: value
        }
    ));
}

export const mergeObjects = (obj1, obj2) => {
    const tmp = {};
    Object.entries(obj1).forEach(([key, val]) => {
        // is object
        if (!Array.isArray(val) && typeof val === 'object' && val !== null) {
            if (obj2.hasOwnProperty(key)) {
                tmp[key] = mergeObjects(val, obj2[key]);
            } else {
                tmp[key] = obj1[key];
            }
        } else if (Array.isArray(val)) {
            tmp[key] = obj2.hasOwnProperty(key) ? obj2[key] : obj1[key];
        } else {
            tmp[key] = obj2.hasOwnProperty(key) ? obj2[key] : obj1[key];
        }
    });
    return tmp;
}

export const loadInterfaceColors = () => {
    let interfaceColors = localStorage.getItem("interfaceColors");
    if (interfaceColors && interfaceColors !== "") {
        return JSON.parse(interfaceColors);
    }
}

export const getMenuIconColor = (isActive) => {
    const interfaceColors = loadInterfaceColors();
    if (interfaceColors?.menuItem?.icon && interfaceColors?.menuItemActive?.icon) {
        return isActive ? interfaceColors.menuItemActive.icon : interfaceColors.menuItem.icon;
    }
}

export const getNavbarButtonStyles = (isActive) => {
    const interfaceColors = loadInterfaceColors();
    const styles = {};
    if (interfaceColors?.menuItem?.background && interfaceColors?.menuItemActive?.background) {
        styles.backgroundColor = isActive ? interfaceColors.menuItemActive?.background : interfaceColors.menuItem?.background;
    }

    if (interfaceColors?.menuItem?.text && interfaceColors?.menuItemActive?.text) {
        styles.color = isActive ? interfaceColors.menuItemActive?.text : interfaceColors.menuItem?.text;
    }

    return styles;
}

export const getButtonStyles = (variant, interfaceColors = loadInterfaceColors()) => {
    const styles = {};
    if (interfaceColors?.[variant]?.text) {
        styles.color = interfaceColors[variant].text;
    }
    if (interfaceColors?.[variant]?.background) {
        styles.backgroundColor = interfaceColors[variant].background;
    }
    return styles;
}

const brickVariants = {
    0: 'brick14',
    1: 'brick2',
    2: 'brick3',
    3: 'brick14',
};
export const getBrickStyles = (n, interfaceColors = loadInterfaceColors()) => {
    const styles = {};
    const variant = brickVariants[n];
    if (interfaceColors?.[variant]?.background) {
        styles.div = {backgroundColor: interfaceColors[variant].background};
    }
    if (interfaceColors?.[variant]?.desc) {
        styles.label = {color: interfaceColors[variant].desc};
    }
    if (interfaceColors?.[variant]?.value) {
        styles.value = {color: interfaceColors[variant].value};
    }
    return styles;
}

export const getLogoPath = () => {
    return localStorage.getItem("logo");
}

export const prepareFormData = formData => {
    const newData = {};
    Object.entries(formData).forEach(([key, val]) => {
        if (val && val !== "") {
            newData[key] = val;
        }
    });
    return newData;
}
export const uniqueId = (prefix = 'id-') => prefix + Math.random().toString(16).slice(-8);

export const inputsContainer = content => <div className="row">{content}</div>;

export const renderError = (error, withDate = false) => {
    let strError;
    switch (error.type) {
        case 'latency':
            strError = t('latencyError', {value: error.error});
            break;
        case 'signalStrength':
            strError = t('signalStrengthError', {value: error.error});
            break;
        default:
            strError = '';
    }

    if (withDate) {
        return `${moment(error.createdAt).format('DD.MM. HH:mm: ')}${strError}`
    }
    return strError;
}

export const getTitlesFromErrorList = errorList => {
    return errorList.map(renderError).join(', ');
}

export const formatDataByColumns = (columns, data) => {
    return data.map(item => {
        const newItem = {};
        columns.forEach(column => {
            if (column.valueRenderer) {
                newItem[column.title] = column.valueRenderer({data: item, ...item});
            } else if (column.linkRenderer && item[column.key]) {
                newItem[column.title] = column.linkRenderer(item).label;
            } else {
                newItem[column.title] = item[column.key];
            }
        });
        return newItem;
    });
}

export const downloadCSV = (dataArray, filename) => {
    const csvRows = [];
    const headers = Object.keys(dataArray[0]).join(',');
    csvRows.push(headers);

    for (const obj of dataArray) {
        const row = Object.values(obj).map(val =>
            `"${String(val).replace(/"/g, '""')}"` // Escape uvozovek
        ).join(',');
        csvRows.push(row);
    }

    const csvString = csvRows.join('\n');

    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}