import {useEffect, useRef, useState} from 'react';
import useFetch from "./useFetch";
import AuthService from "../AuthService";

const authService = new AuthService();

export default function useOverviewData(url, pageSize = 10, initialBricksData = null) {
    const [bricksData, setBricksData] = useState(initialBricksData);
    const [filter, _setFilter] = useState({});
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [pageInfo, _setPageInfo] = useState({pageIndex: 0, pageSize: pageSize});
    const [filterValues, _setFilterValues] = useState();
    const {isLoading, error, response, setError, setResponse} = useFetch(url, 'GET', pageInfo);
    const timeOut = useRef(null);

    // fill bricks data
    useEffect(() => {
        if (bricksData) {
            let newBricksData = bricksData.map(brickData => {
                // handle response
                if (response) {
                    if (brickData.valueRenderer) {
                        brickData.data = brickData.valueRenderer(response);
                    } else {
                        brickData.data = response[brickData.dataKey];
                    }
                } else {
                    // handle error
                    brickData.data = 'Error';
                }
                brickData.isLoading = isLoading;
                return brickData;
            });
            setBricksData(newBricksData);
        }
    }, [isLoading]);

    // set totalItemsCount
    useEffect(() => {
        if (response?.totalCount) {
            setTotalItemsCount(response.totalCount);
        }
        if (response?.filterValues) {
            _setFilterValues(response.filterValues);
        }
        if (response?.pageSize) {
            const newPageInfo = {...pageInfo};
            newPageInfo.pageSize = response.pageSize;
            _setPageInfo(newPageInfo);
        }
    }, [response]);

    const setFilter = (filter) => {
        _setFilter(filter);
        clearTimeout(timeOut.current);
        timeOut.current = setTimeout(async () => {
            await customCall(filter, pageInfo);
        }, 200);
    }

    const setPageIndex = async (pageIndex) => {
        const newPageInfo = {...pageInfo};
        newPageInfo.pageIndex = pageIndex;
        _setPageInfo(newPageInfo);
        await customCall(filter, newPageInfo);
    }

    const _buildFilter = (filter) => {
        const newFilter = {};
        Object.entries(filter).forEach(([key, {type, value}]) => {
            switch (type) {
                case 'dateRange':
                    newFilter[key] = {
                        type,
                        value: {
                            startDate: value.dateRange.startDate,
                            endDate: value.dateRange.endDate,
                        }
                    };
                    break;
                case 'select':
                    if (value === '') {
                        delete newFilter[key];
                    } else {
                        newFilter[key] = {
                            type,
                            value
                        }
                    }
                    break;
                case 'bool':
                    if (value === '') {
                        delete newFilter[key];
                    } else {
                        value = JSON.parse(value);
                        newFilter[key] = {
                            type,
                            value
                        };
                    }
                    break;
                default:
                    newFilter[key] = {
                        type,
                        value
                    };
                    break;
            }
        });
        return newFilter;
    };

    const customCall = async (filter, pageInfo) => {
        try {
            setError(null);
            const {data} = await authService.post(url, {
                filter: _buildFilter(filter),
                pageInfo,
            });
            setResponse(data);
            if (data.hasOwnProperty('totalFilteredCount')) {
                setTotalItemsCount(data.totalFilteredCount);
            }
        } catch (e) {
            setError(e);
        }
    }

    const loadAllFilteredData = async () => {
        try {
            setError(null);
            const {data} = await authService.post(url, {
                filter: _buildFilter(filter),
            });
            return data.list;
        } catch (e) {
            setError(e);
        }
    }

    return {
        isLoading,
        error,
        response,
        bricksData,
        setFilter,
        filter,
        totalItemsCount,
        pageInfo,
        setPageIndex,
        filterValues,
        loadAllFilteredData
    }
}
