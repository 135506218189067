import {useEffect, useState} from 'react';
import AuthService from "../AuthService";

const authService = new AuthService();

export default function useFetch(url, method = 'GET', formData, headers) {
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.fetch(url, method, formData, headers);
                setResponse(data);
                setIsLoading(false);
                setError(null);
            } catch (error) {
                console.error(error);
                setError(error);
                setIsLoading(false);
            }
        })();
    }, []);

    return {
        isLoading,
        error,
        setError: (e) => setError(e),
        response,
        setResponse: (res) => setResponse(res)
    }
}
