import * as React from "react";
import useComponentVisible from "../hooks/useComponentVisible";

import './Dropdown.css';

function Dropdown(props) {
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const _handleClick = callback => {
        setIsComponentVisible(false);
        callback();
    }

    const _renderOptions = () => {
        const options = props.options.map(option =>
            <span onClick={() => _handleClick(() => option.onClick(props.data))}>
                {option.label}
            </span>
        );
        return (
            <div className="FormDropdown-container">
                {React.Children.toArray(options)}
            </div>
        );
    }

    return (
        <div ref={ref} className="FormDropdown">
            <label onClick={() => setIsComponentVisible(true)}>{props.label}</label>
            {isComponentVisible && _renderOptions()}
        </div>
    );

}

export default Dropdown;