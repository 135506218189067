import {useState} from 'react';
import decode from "jwt-decode";

const tokenKey = 'accessToken';

export default function useToken() {

    const isTokenExpired = (token) => {
        try {
            const decoded = decode(token);
            return new Date(decoded.exp * 1000) < Date.now(); // Checking if token is expired. N
        } catch (err) {
            return false;
        }
    }

    const getToken = () => {
        const token = localStorage.getItem(tokenKey);
        if (isTokenExpired(token)) {
            // authService.logout();
            // return null;
        }
        return token;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        if (userToken) {
            localStorage.setItem(tokenKey, userToken);
        }
        setToken(getToken());
    };

    return {
        setToken: saveToken,
        token
    }
}
