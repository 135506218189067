import React, {useEffect, useState} from 'react';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Forms from "../../form/Forms";
import {inputsContainer, onChange} from "../../tools";
import AuthService from "../../AuthService";
import config from "../../config/config";
import t from "../../hooks/useTranslation";
import {mergeObjects} from "../../tools";
import {useParams} from "react-router-dom";
import moment from "moment";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ShelfConfigForm from "./ShelfConfigForm";

import './ShelfForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    name: '',
    serialNumber: '',
    config: {
        shelfConfig: [
            [{width: 500, product: "", productId: null, alarmFrom: 5}],
        ],
        XSize: 120,
        YSize: 50,
        numOfPartsForDelayAlarm: 0,
        delay_s: 0,
        calibration: 212
    },
    scheduledTaskList: []
}

function ShelfForm(props) {
    let {id} = useParams();
    const [shelfLogs, setShelfLogs] = useState([]);
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [validationErrors, setValidationErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLogsLoading, setIsLogsLoading] = useState(true);

    useEffect(() => {
        if (props.data) {
            const data = mergeObjects(INITIAL_FORM_DATA, props.data);
            setFormData(data);
        }
    }, [props.data]);

    useEffect(async () => {
        if (id) {
            // load shelfLogs
            const {data} = await authService.get(config.apiRouteMap.getShelfLog(id));
            setShelfLogs(data);
            setIsLogsLoading(false);
        }
    }, [formData]);

    const onSubmit = async (scheduledTaskList = []) => {
        try {
            let validationErrors = [];
            setValidationErrors([]);
            setIsLoading(true);
            let shelf = {...formData};
            shelf.scheduledTaskList = scheduledTaskList;
            if (!scheduledTaskList.find(cmd => cmd.cmd === "getcfg")) {
                shelf.scheduledTaskList.push({cmd: 'getcfg', executionDate: new Date()});
            }
            shelf.config.numOfRegals = shelf.config.shelfConfig.length;
            shelf.config.shelfConfig.forEach((shelfConfig, i) => {
                let shelfSectorWidth = 0;
                shelf.config[`weightLimit${i + 1}`] = shelfConfig.reduce((min, curVal) => {
                    shelfSectorWidth += parseInt(curVal.width);
                    if (curVal?.product) {
                        let weight = curVal.product.match(/\((\d+)g\)$/);
                        if (weight) {
                            weight = parseInt(weight[1]);
                            if (min === null) {
                                min = weight;
                            } else {
                                min = weight < min ? weight : min;
                            }
                        }
                    }
                    return min;
                }, null);
                if (shelfSectorWidth !== parseInt(shelf.config.XSize)) {
                    validationErrors.push(t('shelfSectorWidthNotSameWidthAsShelfWidth', {shelfNumber: i + 1}));
                }
            });

            // check for errors
            if (validationErrors.length) {
                setValidationErrors(validationErrors);
            } else {
                if (id) {
                    await authService.put(config.apiRouteMap.getShelf(id), shelf);
                } else {
                    const {data} = await authService.post(config.apiRouteMap.shelf, shelf);
                    id = data._id;
                }
                setError(null);
                NotificationManager.success(t('changesSaved'));
            }

        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    const addCmd = (cmd) => {
        onSubmit([{cmd, executionDate: new Date()}]);
    }

    const deleteShelf = async () => {
        if (window.confirm(t('confirmDelete'))) {
            try {
                await authService.delete(config.apiRouteMap.getShelf(id));
            } catch (e) {
                setError(e);
            }
            props.history.push(config.routeMap.shelves);
        }
    }

    const setShelfConfig = (shelfConfigData) => {
        const shelf = formData;
        shelf.config.shelfConfig = shelfConfigData;
        setFormData(shelf);
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={e => e.preventDefault()} autoComplete="off">
                        <div className="row">
                            <Forms.Text label={t('name')} value={formData.name} name='name'
                                        disabled={isLoading} onChange={onChange(setFormData)}/>
                            <Forms.Text label={t('serialNumber')} value={formData.serialNumber} name='serialNumber'
                                        disabled={id} onChange={onChange(setFormData)}/>
                        </div>

                        <Forms.NestedForm name='config' disabled={isLoading} value={formData.config}
                                          onChange={onChange(setFormData)} className="grid" label={t('shelfConfig')}
                                          inputContainer={inputsContainer}>
                            <Forms.Number name="XSize" disabled={isLoading} label={t('XSize')} min={0}/>
                            <Forms.Number name="YSize" disabled={isLoading} label={t('YSize')} min={0}/>
                            <Forms.Number name="numOfPartsForDelayAlarm" disabled={isLoading}
                                          label={t('numOfPartsForDelayAlarm')} min={0}/>
                            <Forms.Number name="calibration" disabled={isLoading}
                                          label={t('calibration')} min={0}/>
                            <Forms.Number name="delay_s" disabled={isLoading} label={t('delayS')}/>
                            <ShelfConfigForm name="shelfConfig" disabled={isLoading} customOnChange={setShelfConfig}
                                             shelfWidth={formData.config.XSize}/>
                        </Forms.NestedForm>

                    </form>
                </div>
                {error && <Error error={error}/>}
                {validationErrors && validationErrors.map(error => <p className="validation-error">{error}</p>)}
                <div className="formActions">
                    <div>
                        <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                        {id && <Forms.Button label={t('delete')} dark onClick={deleteShelf}/>}
                        {id && <Forms.Button label={t('reset')} dark onClick={() => addCmd('reset')}/>}
                        {id && <Forms.Button label={t('getCfg')} dark onClick={() => addCmd('getcfg')}/>}
                        {id && <Forms.Button label={t('tare')} dark onClick={() => addCmd('tare')}/>}
                    </div>
                    <div>
                        <Forms.Button label={t('save')} onClick={() => onSubmit()} disabled={isLoading}/>
                    </div>
                </div>
            </>
        );
    }

    const _renderShelfLogRows = () => {
        return shelfLogs.map(({createdAt, type, data}) => (
            <tr>
                <td>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</td>
                <td>{type === "cmd" ? t(`${type}LogType`, {date: moment(data.scheduledDate).format('DD.MM.YYYY HH:mm')}) : t(`${type}LogType`)}</td>
                <td>{data.log}</td>
            </tr>
        ))
    }

    const renderShelfData = () => {
        return (
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>{t('logDate')}</th>
                        <th>{t('logType')}</th>
                        <th>{t('logData')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {React.Children.toArray(_renderShelfLogRows())}
                    </tbody>
                </table>
            </div>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    const renderData = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderShelfData();
        }
    };

    return (
        <React.Fragment>
            <NotificationContainer/>
            <div className="ShelfContainer">
                {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
            </div>
            {(shelfLogs && shelfLogs.length) ?
                <div className='shelfLogs'>
                    <h3>{t('shelfLogs')}</h3>
                    <div className="ShelfContainer">
                        {isLogsLoading ? <div className="loading"><Loading/></div> : renderData()}
                    </div>
                </div> : ''}
        </React.Fragment>
    );
}

export default ShelfForm;