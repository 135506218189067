import * as React from "react";
import config from "../../config/config";
import {Link} from "react-router-dom";
import useOverviewData from "../../hooks/useOverviewData";
import Overview from "../../components/OverviewTable/Overview";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import t from "../../hooks/useTranslation";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './Shelves.css';

const INITIAL_BRICKS_DATA = [
    {
        label: t('numOfConnectedShelves'),
        isLoading: true,
        dataKey: 'numOfConnectedShelves'
    },
    {
        label: t('onlineStatus'),
        isLoading: true,
        dataKey: 'onlineStatus'
    },
    {
        label: t('numOfConnectedShelveSectors'),
        isLoading: true,
        dataKey: 'numOfConnectedShelveSectors'
    },
    {
        label: t('alarmsPerDay'),
        isLoading: true,
        dataKey: 'alarmsPerDay',
    }
];

function Shelves() {
    const {
        isLoading,
        error,
        response,
        bricksData
    } = useOverviewData(config.apiRouteMap.shelf, 100, INITIAL_BRICKS_DATA);

    const columns = [
        {
            key: 'serialNumber',
            title: t('serialNumber'),
            valueRenderer: (data) => {
                const list = [<Link
                    to={config.routeMap.getShelfDetail(data._id)}>{data.serialNumber}</Link>];
                if (data.errorList.length) {
                    list.unshift(<FontAwesomeIcon title={t('errorsWereFound', {count: data.errorList.length})}
                                                  icon="exclamation-circle"/>)
                }
                return <div className="serialNumber">{React.Children.toArray(list)}</div>;
            }
        },
        {
            key: 'name',
            title: t('name')
        },
        {
            key: 'fwVer',
            title: t('fwVer'),
            valueRenderer: (shelf) => {
                let ver = shelf?.sw?.shelfVersion;
                let updateVer = shelf?.sw?.getFwReturnedVersion;
                if (updateVer && ver && updateVer === ver) {
                    return ver;
                } else if (updateVer && ver) {
                    return `${ver} => ${updateVer}`;
                } else if (updateVer) {
                    return `=> ${updateVer}`;
                } else if (ver) {
                    return ver;
                }
            }
        },
        {
            key: 'lastPing',
            title: t('online'),
            valueRenderer: ({lastPing}) =>
                <span
                    title={moment(lastPing).format('DD.MM.YYYY HH:mm')}>{(new Date().getTime() - 120 * 1000) < new Date(lastPing).getTime() ? t('yes') : t('no')}</span>
        },
        {
            key: 'logCount',
            title: t('logCount'),
            valueRenderer: ({logCount}) => logCount
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="button secondary small" to={config.routeMap.getShelfDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [
        <Link className="button secondary" to={config.routeMap.addShelf}>{t('newShelf')}</Link>
    ];

    return (
        <div className="Shelves app__main">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('shelfAdministration')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('deviceList')} columns={columns}
                               rows={response?.list}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Shelves;
