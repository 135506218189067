import './LoginButton.css';

function LoginButton(props) {
    let className = "LoginButton";
    if (props.className) {
        className += ` ${props.className}`;
    }

    if (props.disabled) {
        className += " disabled";
    }

    let disabled = props.disabled || false;
    return (
        <button className={className} onClick={props.onClick} disabled={disabled}>
            {props.label}
        </button>
    );
}

export default LoginButton;