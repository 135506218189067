import React, {useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";

import useToken from './hooks/useToken';

import Login from "./routes/Login";
import Navbar from "./routes/Navbar";


import Shelves from "./routes/shelves/Shelves";
import LoggedUser from "./components/LoggedUser";
import config from "./config/config";
import ShelfDetail from "./routes/shelves/ShelfDetail";
import Settings from "./routes/settings/Settings";
import AllEvents from "./routes/allEvents/AllEvents";

import ShelfAdd from "./routes/shelves/ShelfAdd";
import Notifications from "./routes/notifications/Notifications";
import Products from "./routes/products/Products";
import ProductAdd from "./routes/products/ProductAdd";
import ProductDetail from "./routes/products/ProductDetail";
import Alarms from "./routes/alarms/Alarms";

// react-date-range
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './App.css';
import Updates from "./routes/updates/Updates";
import UpdateDetail from "./routes/updates/UpdateDetail";


function App() {
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.reload();
        }, 1000 * 60 * 15); // 15 minut

        return () => clearTimeout(timer);
    }, []);
    const {token, setToken} = useToken();

    if (!token) {
        return <Login setToken={setToken}/>
    }

    return (
        <div className="app">
            <Router>
                <Navbar/>
                <section className="app__body">
                    <LoggedUser token={token} setToken={setToken}/>
                    <Switch>
                        <Route exact path="/" render={() => {
                            return (token && <Redirect to={config.routeMap.shelves}/>);
                        }}/>

                        {/* index routes */}
                        <Route path={config.routeMap.shelves} component={Shelves}/>
                        <Route path={config.routeMap.notifications} component={Notifications}/>
                        <Route path={config.routeMap.alarms} component={Alarms}/>
                        <Route path={config.routeMap.products} component={Products}/>
                        <Route path={config.routeMap.settings} component={Settings}/>
                        <Route path={config.routeMap.allEvents} component={AllEvents}/>
                        <Route path={config.routeMap.updates} component={Updates}/>

                        {/*/!* add routes *!/*/}
                        <Route path={config.routeMap.addShelf} component={ShelfAdd}/>
                        <Route path={config.routeMap.addProduct} component={ProductAdd}/>

                        {/*/!* edit routes *!/*/}
                        <Route path={config.routeMap.editShelf} component={ShelfDetail}/>
                        <Route path={config.routeMap.editProduct} component={ProductDetail}/>
                        <Route path={config.routeMap.detailUpdate} component={UpdateDetail}/>
                    </Switch>
                </section>
            </Router>
        </div>
    );
}

export default App;
