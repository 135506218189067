import useFetch from "./useFetch";
import {useParams} from "react-router-dom";

export default function useDetailData(getUrl) {
    const {id} = useParams();
    const {isLoading, error, response} = useFetch(getUrl(id), 'GET');

    return {
        isLoading,
        error,
        response,
    }
}
